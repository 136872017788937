import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Form, Formik, Field } from "formik";

import {
  Button,
  ButtonTypes,
  Newsletter,
  PageHeader
} from "benlux-ecommerce-ui";
import { FormField } from "../../account/SignIn/FormField";
import {
  ConfigureNewsletterMutation,
  SubscribeToNewsletterMutation
} from "../../../mutation/NewsletterMutation";

import "./index.css";
import * as Yup from "yup";

const subscribeToNewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .email("Veuillez entrer une adresse e-mail valide.")
    .required("Veuillez entrer une adresse e-mail.")
});

const configureNewsletterSchema = Yup.object().shape({
  gender: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  birthDate: Yup.string().required()
});

export const NewsletterForm = ({ onCompleted }) => {
  return (
    <SubscribeToNewsletterMutation>
      {(subscribeToNewsletter, { loading, error }) => {
        return (
          <Formik
            initialValues={{
              email: ""
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={subscribeToNewsletterSchema}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, resetForm }
            ) => {
              setSubmitting(true);
              try {
                const result = await subscribeToNewsletter({
                  variables: {
                    input: {
                      ...values
                    }
                  }
                });
                resetForm();
                setStatus({ success: true });
                onCompleted({ email: values.email });
              } catch (e) {
                setStatus({ success: false });
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, status, values }) => {
              return (
                <Form>
                  {!isSubmitting && status && status.success && (
                    <p>
                      Merci pour votre inscription. Vous recevrez désormais les
                      bons plans Benlux par e-mail !
                    </p>
                  )}
                  <FormField
                    name="email"
                    type="email"
                    placeholder={"Adresse e-mail"}
                  />
                  <Button
                    type={ButtonTypes.Secondary}
                    as={"submit"}
                    text={"S'inscrire"}
                    loading={loading}
                    disabled={isSubmitting}
                  />
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </SubscribeToNewsletterMutation>
  );
};

const Index = ({ location, history }) => {
  const { search } = location;
  const params = new URLSearchParams(search);
  const email = params.get("email");

  return (
    <>
      <Helmet>
        <title>Newsletter</title>
      </Helmet>
      <PageHeader
        image={""}
        title={"La Newsletter Benlux"}
        description={"Recevez tous nos bons plans dans votre boîte e-mail !"}
      />
      <div className="newsletter-page-wrapper">
        {email ? (
          <>
            <h2>Merci pour votre inscription !</h2>
            {/* <p>
              Votre code vient d'être envoyé par e-mail à l'adresse {email}.
            </p> */}
            <h3 style={{ marginTop: "24px" }}>
              Recevez des offres personnalisées en configurant votre newsletter
            </h3>
            <ConfigureNewsletterMutation>
              {(configureNewsletter, { loading, error }) => (
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={configureNewsletterSchema}
                  onSubmit={async (values, { setSubmitting, setStatus }) => {
                    setSubmitting(true);
                    try {
                      const result = await configureNewsletter({
                        variables: {
                          input: {
                            email,
                            ...values
                          }
                        }
                      });
                      setStatus({ success: true });
                    } catch (e) {
                      console.error(e);
                      setStatus({ success: false });
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, status, values, errors, isValid }) => (
                    <Form>
                      <div
                        role="group"
                        aria-labelledby="gender-group"
                        style={{ display: "flex", marginBottom: "12px" }}
                      >
                        <label style={{ marginRight: "12px" }}>
                          <Field type="radio" name="gender" value="female" />
                          Femme
                        </label>
                        <label>
                          <Field type="radio" name="gender" value="male" />
                          Homme
                        </label>
                      </div>
                      <div style={{ display: "flex", marginBottom: "12px" }}>
                        <div style={{ flex: 1, marginRight: "12px" }}>
                          <label htmlFor="firstName">Prénom</label>
                          <FormField
                            id="firstName"
                            name="firstName"
                            placeholder="Prénom"
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label htmlFor="lastName">Nom</label>
                          <FormField
                            id="lastName"
                            name="lastName"
                            placeholder="Nom"
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="birthDate">Date de naissance</label>
                        <FormField
                          id="birthDate"
                          name="birthDate"
                          type="date"
                          placeholder="10/10/1990"
                        />
                      </div>
                      <Button
                        type={ButtonTypes.Secondary}
                        as={"submit"}
                        text={"Enregistrer mes préférences"}
                        loading={isSubmitting}
                      />
                      {!isSubmitting && status && status.success && (
                        <p style={{ marginTop: "12px" }}>
                          Vos préférences ont bien été enregistrées !
                        </p>
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </ConfigureNewsletterMutation>
          </>
        ) : (
          <>
            <h2>
              Offres spéciales, bons plans, promotions en exclusivité dans votre
              boîte e-mail !
            </h2>
            <NewsletterForm
              onCompleted={({ email }) => {
                history.push(`/newsletter?email=${email}`);
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Index;
